import {Chip, Menu, MenuItem} from "@mui/material";
import RecordingService, {Recording, RecordingActions} from "../../services/RecordingService";
import {IRowNode} from "ag-grid-community";
import {useState} from "react";

export const RecordingActionTag = ({data, rowNode, callback}: {data:Recording, rowNode: IRowNode<Recording>, callback: (id: string | undefined, action: RecordingActions) => void}) => {
    let tagColor;
    let tagText;

    const [anchorEl, setAnchorEl] = useState(null);
    const actionEnum = RecordingActions[data.action as keyof typeof RecordingActions];

    const handleClick = (event:any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (action:RecordingActions) => {
        RecordingService.updateRecordingAction(data.id, action).then(() => {
            callback(rowNode.id, action);
    });
        handleClose();
    };


    switch (actionEnum) {
        case RecordingActions.CONTACT_CENTER:
            tagColor = 'orange';
            tagText = 'Contacter le centre';
            break;
        case RecordingActions.FILE_OK:
            tagColor = 'green';
            tagText = 'Fichier OK';
            break;
        case RecordingActions.FILE_TO_CHECK:
            tagColor = '#d83cba';
            tagText = 'Fichier à vérifier';
            break;
        case RecordingActions.CONTACT_MADE_RESOLUTION_IN_PROGRESS:
            tagColor = '#ffd604';
            tagText = 'Contact fait - résolution en cours';
            break;
        case RecordingActions.RESOLUTION_DONE:
            tagColor = 'blue';
            tagText = 'Données validées';
            break;
        case RecordingActions.UPDATE_DASHBOARD:
            tagColor = '#8e04ff';
            tagText = 'Mettre à jour le dashboard';
            break;
        default:
            tagColor = '#d83cba';
            tagText = 'Fichier à vérifier';
    }

    return (
        <>
            <Chip label={tagText} clickable={true} onClick={handleClick} style={{backgroundColor: tagColor, color: 'white'}} />
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={() => handleMenuItemClick(RecordingActions.FILE_TO_CHECK)}>Fichier à vérifier</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(RecordingActions.FILE_OK)}>Fichier ok</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(RecordingActions.CONTACT_CENTER)}>Contacter le centre</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(RecordingActions.CONTACT_MADE_RESOLUTION_IN_PROGRESS)}>Contact fait - résolution en cours</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(RecordingActions.RESOLUTION_DONE)}>Données validées</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(RecordingActions.UPDATE_DASHBOARD)}>Mettre à jour le dashboard</MenuItem>
            </Menu>
        </>
    );
};



