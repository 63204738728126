import {Button, Typography} from "@mui/material";
import React, {useEffect, useRef, useState} from 'react';
import moment from "moment";
import {Comment} from "../../../Redux/ServerTypes";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css'
import DOMPurify from 'dompurify';
import CommentService from "../../../services/CommentService";
import {User} from "../../../hooks/auth";
import './CommentStyle.css'

export const CommentRenderer = ({ user, comments, setComments }: { user?: User | null, comments: Comment[], setComments: (comments: Comment[]) => void }) => {

    const [openTextEditor, setOpenTextEditor] = useState<boolean>(false);
    const [editedCommentId, setEditedCommentId] = useState<string|undefined>('');
    const [valueEdited, setValueEdited] = useState<string>('');
    const lastCommentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (lastCommentRef.current) {
            lastCommentRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }, [comments, openTextEditor]);

    function handleEdit(comment: Comment) {
        setEditedCommentId(comment.id);
        setValueEdited(comment.content);
        setOpenTextEditor(true);
    }

    function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            if (openTextEditor && editedCommentId) {
                updateComment(editedCommentId, valueEdited);
                setOpenTextEditor(false);
            }
        }
    }

    const dateDisplay = (date: Date): string => {
        return moment(date).format("D MMMM YYYY HH:mm");
    };

    function updateComment(id: string, newContent: string) {
        CommentService.updateComment(id, newContent)
            .then((updatedComment) => {
                const updatedComments = comments.map(comment => {
                    if (comment.id === id) {
                        comment.content = updatedComment.content;
                        comment.editedAt = updatedComment.editedAt;
                    }
                    return comment;
                });
                setComments(updatedComments)
            })
            .catch((error) => {
                console.error('Erreur lors de la modification du commentaire :', error);
            });
    }

    return (
        <div>
            {comments.map((comment: Comment, index: number) => {
                const finalComment: { [key: string]: any } = {
                    createdAt: comment.createdAt,
                    editedAt: comment.editedAt,
                    userFirstname: comment.user?.firstname,
                    content: comment.content
                }

                return (
                    <div ref={index === comments.length - 1 ? lastCommentRef : null}>
                        <div key={comment.id} style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                            <Typography color="textPrimary" variant="body1">
                                {finalComment.userFirstname} - <i
                                style={{fontSize: 'small'}}>{dateDisplay(finalComment.createdAt)}</i>
                                {finalComment.editedAt && (
                                    <span>  (edited on <i
                                        style={{fontSize: 'small'}}>{dateDisplay(finalComment.editedAt)}</i>)</span>)}
                            </Typography>
                            {comment.userId === user?.id && (
                                <Button onClick={() => handleEdit(comment)} color={"primary"}
                                        size="small">
                                    Edit
                                </Button>
                            )}
                        </div>
                        <Typography color="textPrimary" variant="body2"
                                    dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(finalComment.content)}}></Typography>
                        {openTextEditor && editedCommentId === comment.id && <>
                            <ReactQuill theme={'snow'} value={valueEdited} onChange={setValueEdited}
                                        onKeyDown={handleKeyDown}></ReactQuill>
                            <Button onClick={() => setOpenTextEditor(false)}
                                    color={"primary"}
                                    size="small">
                                Cancel
                            </Button>
                            <Button onClick={() => {
                                setOpenTextEditor(false);
                                if (comment.id) {
                                    updateComment(comment.id, valueEdited)
                                }
                            }}
                                    color={"primary"}
                                    size="small">
                                Update
                            </Button></>
                        }
                    </div>
                );
            })}
        </div>
    )
}