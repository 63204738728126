import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, Link, Menu, MenuItem } from "@mui/material";
import { IRowNode } from "ag-grid-community";
import { NestedMenuItem } from "mui-nested-menu";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { User } from '../../../hooks/auth';
import { useSnackbar } from "../../../hooks/snackbar";
import RecordingService, { Recording, RecordingStatus } from "../../../services/RecordingService";
import { UpdateStatusDialog } from "../../components/status/UpdateStatus";
import { ClinicalStudyEnum, clinicalStudies } from "../../models/clinicalStudy";


export const RecordingGridMenu = ({ user, data, rowNode, callback }: { user?: User | null, data: Recording, rowNode: IRowNode<Recording>, callback: (id: string | undefined, status: RecordingStatus) => void; }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const navigate = useNavigate();
    const open = Boolean(anchorEl);
    const { setSnackbar } = useSnackbar();
    const [openDialog, setOpenDialog] = useState(false);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    if(data === undefined) {
        return <></>;
    }

    let currentStudy = clinicalStudies.get(ClinicalStudyEnum.EITHEALTH);

    if(data.clinicalStudy !== undefined) {
        currentStudy = clinicalStudies.get(data.clinicalStudy as ClinicalStudyEnum);
    }

    const copyId = (id: string) => {
        navigator.clipboard.writeText(id);
        setSnackbar({ message: "ID copied to clipboard" });
    }
    return <>
        <IconButton onClick={handleClick}
            aria-controls={`menu-${data.id}`}
            aria-haspopup="true"

        >
            <MenuIcon></MenuIcon>
        </IconButton>
        <Menu
            id={`menu-${data.id}`}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}

        >
            {user?.role === "ADMIN" &&
                <NestedMenuItem
                    label="Liens"
                    parentMenuOpen={open}
                >
                    {data.s3Link &&
                        <MenuItem>
                            <Link href={data.s3Link} target="_blank">Accès au recording Apneal</Link>
                        </MenuItem>}
                    {data.s3FormsLink &&
                        <MenuItem>
                            <Link href={data.s3FormsLink} target="_blank">Accès au questionnaires</Link>
                        </MenuItem>}
                    {data.s3PSGLink &&
                        <MenuItem>
                            <Link href={data.s3PSGLink} target="_blank">Accès à la PSG</Link>
                        </MenuItem>}
                    {user?.role === "ADMIN" && data.dashboardLink &&
                        <MenuItem>
                            <Link href={data.dashboardLink} target="_blank">Dashboard Apneal</Link>
                        </MenuItem>
                    }
                </NestedMenuItem>
            }



            {(currentStudy?.psgUpload || currentStudy?.formUpload) &&
                <NestedMenuItem
                    label="Téléchargement"
                    parentMenuOpen={open}
                >
                    {currentStudy?.psgUpload &&
                        <MenuItem onClick={() => navigate(`/app/recordings/${data.id}/psg-upload`)}>
                            Téléchargement de la PSG
                        </MenuItem>}
                    {currentStudy?.formUpload &&
                        <MenuItem onClick={() => navigate(`/app/recordings/${data.id}/form-upload`)}>
                            Téléchargement des formulaires
                        </MenuItem>}
                </NestedMenuItem>}

            <MenuItem onClick={() => { copyId(data.id) }}>Copier l'identifiant</MenuItem>

            {user?.role === "ADMIN" && (
                <MenuItem onClick={() => navigate(`/app/audit_trail?scheduleId=${data.id}`)}>Voir l'audit trail</MenuItem>
            )}

            {user?.role === "ADMIN" &&
                <MenuItem onClick={() => {
                    setOpenDialog(true);
                }}> Modifier le statut</MenuItem>
            }
            {data.status?.toString() !== RecordingStatus[RecordingStatus.TEST] && user?.role === "ADMIN" &&
                <MenuItem onClick={() => {
                    RecordingService.updateRecordingStatus(data.id, RecordingStatus.TEST).then(() => {
                        setSnackbar({ message: "Statut de l'enregistrement mis à jour" });
                        callback(rowNode.id, RecordingStatus.TEST);
                    })
                }}>Marquer comme étant un test
                </MenuItem>
            }
        </Menu>
        <UpdateStatusDialog open={openDialog} schedule={data}
                            handleClose={(status) => {
                                setOpenDialog(false)
                                callback(rowNode.id, status);
                            }}
        ></UpdateStatusDialog>

    </>

}
