import React, {useEffect, useRef, useState} from 'react';
import {Recording} from "../../../services/RecordingService";
import moment from "moment/moment";
import CenterService from "../../../services/CenterService";
import {Center} from "../../../Redux/ServerTypes";
import {Dictionary, groupBy} from "../../../utils/array.utils";
import {AgGridReact} from "ag-grid-react";
import {CellClassParams} from "ag-grid-community";

interface DashboardInclusionTableProps {
    filteredPatientsRecordings?: Recording[],
}

const DashboardInclusionTable: React.FC<DashboardInclusionTableProps> = ({filteredPatientsRecordings}) => {

    const [centers, setCenters] = useState<Dictionary<Center>>({});
    const [isCentersLoaded, setIsCentersLoaded] = useState(false);
    const [agGridData, setAgGridData] = useState<Center[]>([]);
    const gridRef = useRef<AgGridReact>(null);

    useEffect(() => {
        CenterService.getAllCenters().then((centersList) => {
            setCenters(groupBy(centersList, 'clinicalStudy'));
            setIsCentersLoaded(true);
        }).catch((err) => {
            console.log(err);
            setIsCentersLoaded(true);
        });
    }, []);

    useEffect(() => {
        if (isCentersLoaded && filteredPatientsRecordings && centers) {
            const updatedCenters:Dictionary<Center> = Object.keys(centers).reduce((acc:any, clinicalStudy) => {
                const centersArray = centers[clinicalStudy];
                acc[clinicalStudy] = centersArray.map(center => {
                    const filteredRecordings = filteredPatientsRecordings.filter(recording => recording.center === center.name);
                    const recordingCount = filteredRecordings.length;
                    const latestStartDate = filteredRecordings.reduce((previousRecording, recording) => {
                        const recordingDate = new Date(recording.startDate);
                        if (!previousRecording || recordingDate > new Date(previousRecording)) {
                            return recordingDate;
                        }
                        return previousRecording;
                    }, null as Date | null);
                    const formattedStartDate = latestStartDate ? dateDisplay(latestStartDate) : '';
                    return {
                        ...center,
                        patientNumber: recordingCount,
                        latestInclusion: formattedStartDate
                    };
                });
                return acc;
            }, {});
            setCenters(updatedCenters);
        }
    }, [filteredPatientsRecordings]);

    useEffect(() => {
        if (centers) {
            const data: Center[] = [];
            Object.keys(centers).forEach(clinicalStudy => {
                const clinicalStudyCenters = centers[clinicalStudy];
                clinicalStudyCenters.forEach(center => {
                    data.push({...center});
                });
            });
            setAgGridData(data);
        }
    }, [centers]);

    const dateDisplay = (date: Date): string => {
        return moment(date).format("D MMMM YYYY");
    }

    const columnDefs = [
        { headerName: `Etude`, field: 'clinicalStudy', flex: 1 },
        { headerName: `Centre`, field: 'name', flex: 1 },
        { headerName: 'Dernière inclusion', field: 'latestInclusion', flex: 1,
            cellStyle: (params: CellClassParams) => {
                const latestInclusionDate = new Date(params.value);
                const currentDate = new Date();
                const differenceInDays = (currentDate.getTime() - latestInclusionDate.getTime()) / (1000 * 3600 * 24);

                if (differenceInDays > 5) {
                    return { backgroundColor: '#EC7063' };
                } else if (differenceInDays > 3) {
                    return { backgroundColor: '#EB984E' };
                } else {
                    return null;
                }
            }},
        { headerName: 'Nombre de patient inclus', field: 'patientNumber', flex: 1 },
    ];

    return (
        <div className="ag-theme-alpine" style={{width: "100%", marginTop: "3%"}}>
            <AgGridReact
                rowData={agGridData}
                rowHeight={50}
                ref={gridRef}
                columnDefs={columnDefs}
                domLayout="autoHeight"
                enableCellChangeFlash={true}
                defaultColDef={{
                    sortable: true,
                    filter: true,
                }}>
            </AgGridReact>
        </div>
    );
}

export default DashboardInclusionTable;
