import {Chip} from "@mui/material";
import React from "react";

export const PsgAndFormStatusTag = ({psgOrFormsStatus}: { psgOrFormsStatus: string }) => {
    let tagColor;
    let tagText;

    switch (psgOrFormsStatus) {
        case ('uploaded'):
            tagColor = 'green';
            tagText = 'Uploaded';
            break;
        case ('not uploaded'):
            tagColor = 'red';
            tagText = 'Not uploaded';
            break;
        default:
            tagColor = 'gray';
            tagText = 'Not applicable';
    }

    return (
        <Chip label={tagText} style={{backgroundColor: tagColor, color: 'white'}} />
    );
};



