import axios from "axios";
import {Comment} from "../Redux/ServerTypes";

export enum RecordingStatus {
    WAITING_FOR_DATA= "WAITING_FOR_DATA",
    RECORD_SENDING = "RECORD_SENDING",
    DATA_RECEIVED = "DATA_RECEIVED",
    INCLUDED = "INCLUDED",
    TEST = "TEST",
    DATA_VALIDATED = "DATA_VALIDATED",
    ALERT_RAISED = "ALERT_RAISED",
    EXCLUDED = "EXCLUDED",
    NEED_RANDOM_CHECK = "NEED_RANDOM_CHECK"
}

export enum  RecordingActions {
    FILE_TO_CHECK = "FILE_TO_CHECK",
    CONTACT_CENTER = "CONTACT_CENTER",
    CONTACT_MADE_RESOLUTION_IN_PROGRESS = "CONTACT_MADE_RESOLUTION_IN_PROGRESS",
    FILE_OK = "FILE_OK",
    UPDATE_DASHBOARD = "UPDATE_DASHBOARD",
    RESOLUTION_DONE = "RESOLUTION_DONE",
}

export enum  RecordingInvoiceStatus {
    TO_BE_INVOICED = "TO_BE_INVOICED",
    INVOICED = "INVOICED",
    NOT_INVOICABLE = "NOT_INVOICABLE",
    WAITING_FOR_INFO = "WAITING_FOR_INFO",
    NOT_APPLICABLE = "NOT_APPLICABLE"
}

export enum PostNightFormStatus {
    NOT_FILLED,
    FILLED  
}

export type CheckDefinitionValue = {
    checkDefinitionId: string;
    scheduleId: string;
    value: string;
}

export type Recording = {
    id: string;
    clinicalStudy: string;
    center: string;
    patientName: string;
    startDate: string;
    duration: string;
    endTime: number;
    status: RecordingStatus;
    action: RecordingActions;
    invoiceStatus: RecordingInvoiceStatus;
    postNightFormStatus: PostNightFormStatus;
    userAppelation: string;
    userId: string;
    s3Link?: string;
    s3PSGLink?: string;
    s3FormsLink?: string;
    dashboardLink?: string;
    comments: Comment[];
    checks?: CheckDefinitionValue[];
}

const RecordingService = {

    /**
     * Get All recordings for the current user
     * PRACTITIONER can only see the recording from their center
     * ADMIN can see all recordings
     * CLINICAL_TRIAL_ADMIN can see all recordings from their clinical trial
     */
    getRecordingsForUser: async () => {
        return axios.get("/schedule").then( (response) => {
            return response.data as Recording[];
        }).catch( (error) => {
            throw new Error(error);
        });
    },

    /**
     * Update recording status
     * @param id 
     * @param status
     * @returns 
     */
    updateRecordingStatus: async (id: string, status: RecordingStatus) => {
        const statusString = RecordingStatus[status];
        return axios.put(`/schedule/update-status`, {status: statusString, scheduleId: id}).then( (response) => {
            return response.data as Recording;
        }).catch( (error) => {
            throw new Error(error);
        });
    },

    /**
     * Update recording action
     * @param id
     * @param action
     * @returns
     */
    updateRecordingAction: async (id: string, action: RecordingActions) => {
        const actionString = RecordingActions[action];
        return axios.put(`/schedule/update-action`, {action: actionString, scheduleId: id}).then( (response) => {
            return response.data as Recording;
        }).catch( (error) => {
            throw new Error(error);
        });
    },

    /**
     * Update recording invoice status
     * @param id
     * @param invoiceStatus
     * @returns
     */
    updateRecordingInvoiceStatus: async (id: string, invoiceStatus: RecordingInvoiceStatus) => {
        const invoiceStatusString = RecordingInvoiceStatus[invoiceStatus];
        return axios.put(`/schedule/update-invoice-status`, {invoiceStatus: invoiceStatusString, scheduleId: id}).then( (response) => {
            return response.data as Recording;
        }).catch( (error) => {
            throw new Error(error);
        });
    },

    /**
     * Update recording checks
     * @param id 
     * @returns 
     */
    updateRecordingCheck: async (technicalId: string, value: string, scheduleId: string ) => {
        return await axios.put(`/schedule/update-check-auth/`, {
            checkValues: [{
                technicalId: technicalId,
                value: value?.toString()
              }],
            scheduleId: scheduleId
        }).then( (response: { data: any; }) => {
            return response.data;
        }).catch( (error:string) => {
            throw new Error(error);
        });
    },

    getRecording: async (id: string) => {
        return axios.get(`/schedule/${id}`).then( (response) => {
            return response.data as Recording;
        }).catch( (error) => {
            throw new Error(error);
        });
    },

    /**
     * Get signedUrl for a recording
     */
    getSignedUrl: async (id: string, type: string, filename: string) => {
        return axios.get(`/schedule/${id}/signed-url?type=${type}&filename=${encodeURI(filename)}`).then( (response) => {
            return response.data.uploadUrl as string;
        }).catch( (error) => {
            throw new Error(error);
        });
    }

}

export default RecordingService;
