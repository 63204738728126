import {Chip, Menu, MenuItem} from "@mui/material";
import RecordingService, {Recording, RecordingInvoiceStatus} from "../../services/RecordingService";
import {IRowNode} from "ag-grid-community";
import {useState} from "react";
import {ModelRoles, User} from "../../hooks/auth";

export const RecordingInvoiceTag = ({data, user, rowNode, callback}: {data:Recording, user:User, rowNode: IRowNode<Recording>, callback: (id: string | undefined, invoiceStatus: RecordingInvoiceStatus) => void}) => {
    let tagColor;
    let tagText;

    const [anchorEl, setAnchorEl] = useState(null);
    const invoiceEnum = RecordingInvoiceStatus[data.invoiceStatus as keyof typeof RecordingInvoiceStatus];

    const handleClick = (event:any) => {
        if (user.role === ModelRoles.ADMIN){
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (billingStatus:RecordingInvoiceStatus) => {
        RecordingService.updateRecordingInvoiceStatus(data.id, billingStatus).then(() => {
            callback(rowNode.id, billingStatus);
    });
        handleClose();
    };


    switch (invoiceEnum) {
        case RecordingInvoiceStatus.INVOICED:
            tagColor = 'green';
            tagText = 'Facturé';
            break;
        case RecordingInvoiceStatus.TO_BE_INVOICED:
            tagColor = 'orange';
            tagText = 'À facturer';
            break;
        case RecordingInvoiceStatus.NOT_INVOICABLE:
            tagColor = 'red';
            tagText = 'Non facturable';
            break;
        case RecordingInvoiceStatus.WAITING_FOR_INFO:
            tagColor = 'gray';
            tagText = `En attente d'information`;
            break;
        case RecordingInvoiceStatus.NOT_APPLICABLE:
            tagColor = 'darkgray';
            tagText = `Non applicable`;
            break;
        default:
            tagColor = 'gray';
            tagText = `En attente d'information`;
    }

    return (
        <>
            <Chip label={tagText} clickable={user.role === ModelRoles.ADMIN} onClick={handleClick} style={{backgroundColor: tagColor, color: 'white'}} />
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={() => handleMenuItemClick(RecordingInvoiceStatus.INVOICED)}>Facturé</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(RecordingInvoiceStatus.TO_BE_INVOICED)}>À facturer</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(RecordingInvoiceStatus.NOT_INVOICABLE)}>Non facturable</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(RecordingInvoiceStatus.WAITING_FOR_INFO)}>En attente d'information</MenuItem>
            </Menu>
        </>
    );
};



