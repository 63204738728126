import {
  Box,
  Container,
} from "@mui/material";
import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { ModelRoles, useAuth } from "../../hooks/auth";
import RecordingService, { Recording, RecordingStatus } from "../../services/RecordingService";
import DashboardInclusionInvoicingGrid from "../AGGrids/DashboardInclusionInvoingGrid";
import DashboardInclusionCard from "../components/dashboardInclusion/DashboardInclusionCard";
import DashboardInclusionTable from "../components/dashboardInclusion/DashboardInclusionTable";
import { ClinicalStudyEnum } from "../models/clinicalStudy";


const DashboardInclusion: React.FC = () => {

  const [filteredPatientsRecordings, setFilteredPatientsRecordings] = useState<Recording[]>();
  const [easyPatientsCount, setEasyPatientsCount] = useState<number>(0);
  const [sesamePatientsCount, setSesamePatientsCount] = useState<number>(0);
  const [clinicalStudyAdmin, setClinicalStudyAdmin] = useState<ClinicalStudyEnum>();
  const {user} = useAuth();

  useEffect(() => {
    RecordingService.getRecordingsForUser().then((patientRecordings: Recording[]) => {
      const filteredPatientsRecordings = patientRecordings.filter(
          (recording: Recording) => recording.status.toString() !== RecordingStatus[RecordingStatus.TEST]
      );
      setFilteredPatientsRecordings(filteredPatientsRecordings);

      let easyPatientsCount = filteredPatientsRecordings.length;
      let sesamePatientsCount = filteredPatientsRecordings.length;

      if (user && user.role === ModelRoles.ADMIN) {
        const filteredPatientsEithealth = filteredPatientsRecordings.filter(
            (recording: Recording) => recording.clinicalStudy === ClinicalStudyEnum.EITHEALTH
        );
        const filteredPatientsSesame = filteredPatientsRecordings.filter(
          (recording: Recording) => recording.clinicalStudy === ClinicalStudyEnum.SESAME
        );
        easyPatientsCount = filteredPatientsEithealth.length;
        sesamePatientsCount = filteredPatientsSesame.length;
      } else if (filteredPatientsRecordings.length > 0) {
        const clinicalStudyStr = filteredPatientsRecordings[0].clinicalStudy;
        if (Object.values(ClinicalStudyEnum).includes(clinicalStudyStr as ClinicalStudyEnum)) {
          setClinicalStudyAdmin(clinicalStudyStr as ClinicalStudyEnum);
        }
      }

      setEasyPatientsCount(easyPatientsCount);
      setSesamePatientsCount(sesamePatientsCount);
    });
  }, []);

  return (<>
    <Helmet><title>DashboardInclusion | Apneal</title></Helmet>
    <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
      <Container maxWidth={false}>
        <Box sx={{display: 'flex', alignContent: "center", alignItems: "center", justifyContent: 'center', gap: '50px', marginBottom:3}}>
          {(user?.role===ModelRoles.ADMIN || (user?.role===ModelRoles.CLINICAL_STUDY_ADMIN && clinicalStudyAdmin===ClinicalStudyEnum.EITHEALTH)) && <DashboardInclusionCard clinicalStudyName={ClinicalStudyEnum.EITHEALTH} inclusionCount={easyPatientsCount}/>}
          {(user?.role===ModelRoles.ADMIN || (user?.role===ModelRoles.CLINICAL_STUDY_ADMIN && clinicalStudyAdmin===ClinicalStudyEnum.SESAME)) && <DashboardInclusionCard clinicalStudyName={ClinicalStudyEnum.SESAME} inclusionCount={sesamePatientsCount}/>}
        </Box>
        <DashboardInclusionTable filteredPatientsRecordings={filteredPatientsRecordings}/>
        { user?.role===ModelRoles.ADMIN &&
            <DashboardInclusionInvoicingGrid filteredPatientsRecordings={filteredPatientsRecordings}/>
        }
      </Container>
    </Box>
  </>);
}

export default DashboardInclusion;
