import React from 'react';
import {
    Card,
    CardActionArea, CardContent,
    Typography
} from "@mui/material";

interface DashboardInclusionCardProps {
    clinicalStudyName: string,
    inclusionCount: number,
}

const DashboardInclusionCard: React.FC<DashboardInclusionCardProps> = ({clinicalStudyName, inclusionCount}) => {

    function Pluralization(inclusionCount:number) {
        return inclusionCount > 1 ? 'inclusions' : 'inclusion';
    }

    return (<>
        <Card sx={{ maxWidth: 345 }}>
            <CardActionArea>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {clinicalStudyName}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {inclusionCount} {Pluralization(inclusionCount)} sur 500.
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    </>);
}

export default DashboardInclusionCard;
