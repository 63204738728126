import { Helmet } from 'react-helmet';
import { Box, Container } from '@mui/material';
import React from 'react';
import AuditTrailGrid from '../AGGrids/AuditTrailGrid';
import ESStatsGrid from '../AGGrids/ESStatsGrid';
import RoutinesGrid from '../AGGrids/RoutinesGrid';

const AdminPage: React.FC = () => {

  return (<>
    <Helmet><title>Admin | Apneal</title></Helmet>
    <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
      <Container maxWidth={false}>
      <div role="presentation">
    </div>
        <RoutinesGrid/>
      </Container>
    </Box>
  </>);
}

export default AdminPage;
